<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 c g">
      <div class="card">
        <div class="card-header">
          <h4>
            <i class="fa fa-calendar"></i>
            {{ e("freedays-requests") }}
          </h4>
        </div>
        <div class="card-body">
          <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered">
              <thead>
                <th>
                  {{ e("username") }}
                </th>
                <th>
                  {{ e("date-of-request") }}
                </th>
                <th>
                  {{ e("date-or-between") }}
                </th>
                <th>
                  {{ e("status") }}
                </th>
                <th>
                  {{ e("details") }}
                </th>
                <th>
                  {{ e("options") }}
                </th>
              </thead>
              <tbody>
                <tr v-for="request in requests" :key="request._id">
                  <td>{{ request.number }}</td>
                  <td>{{ cdate(request.date) }}</td>
                  <td>
                    {{
                      request.from == request.to
                        ? cdate(request.from)
                        : e("from") +
                          " " +
                          cdate(request.from) +
                          " " +
                          e("to") +
                          " " +
                          cdate(request.to)
                    }}
                  </td>
                  <td>
                    <span v-if="request.status == 1" class="badge bg-success">
                      {{ e("accepted") }}
                    </span>
                    <span v-if="request.status == 2" class="badge bg-danger">
                      {{ e("rejected") }}
                    </span>
                    <span v-if="request.status == 0" class="badge bg-warning">
                      {{ e("waiting") }}
                    </span>
                    <br />
                    <span v-html="request.user_notes"></span>
                  </td>
                  <td>{{ request.details }}</td>
                  <td>
                    <b-dropdown
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :text="e('options')"
                      size="sm"
                      variant="primary"
                    >
                      <b-dropdown-item @click="changeStatus(request, 1)">
                        <i class="fa fa-check-square text-success"></i>
                        {{ e("accept") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="changeStatus(request, 2)">
                        <i class="fa fa-times text-danger"></i>
                        {{ e("reject-request") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteRequest(request)"
                        ><i class="fa fa-trash text-danger"></i>
                        {{ e("delete") }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BLink,
  BOverlay,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
const a2e = (s) => s.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d));
export default {
  components: {
    BLink,
    BOverlay,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      loading: false,
      user: JSON.parse(localStorage.getItem("user")),
      hijri: false,
      start: null,
      members: [],
      end: null,
      requests: [],
    };
  },
  created() {
    if (cdate("2022-02-02").includes("ه")) {
      this.hijri = true;
    }
    this.getRequests();
  },
  methods: {
    e(d) {
      return e(d);
    },
    deleteRequest(request) {
      var g = this;
      if (confirm(g.e("confirm"))) {
        g.loading = true;
        $.post(api + "/general/freedays-requests-delete", {
          jwt: g.user.jwt,
          id: request._id,
        })
          .then(function (r) {
            g.loading = false;
            r = JSON.parse(r);
            if (r.status == 100) {
              g.getRequests();
            } else {
              alert(g.e(r.response));
              g.getRequests();
            }
          })
          .catch(function (e) {
            g.loading = false;
            alert(g.e("error") + ": " + e);
          });
      }
    },
    changeStatus(request, status) {
      var g = this;
      if (confirm(g.e("confirm"))) {
        var t = prompt(g.e("your_notes"), request.user_notes);
        g.loading = true;
        $.post(api + "/general/freedays-requests-change-status", {
          jwt: g.user.jwt,
          id: request._id,
          status: status,
          notes: t,
        })
          .then(function (r) {
            r = JSON.parse(r);
            g.loading = false;
            if (r.status == 100) {
              g.getRequests();
            } else {
              alert(g.e(r.response));
              g.getRequests();
            }
          })
          .catch(function (e) {
            g.loading = false;
            alert(g.e("error") + ": " + e);
          });
      }
    },
    getRequests() {
      var g = this;
      $.post(api + "/general/freedays-requests", {
        jwt: this.user.jwt,
      })
        .then(function (r) {
          r = JSON.parse(r);
          if (r.status == 100) {
            g.requests = r.response;
            g.loading = false;
          } else {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e(r.response),
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch(function (e) {
          g.loading = false;
          alert(g.e("error") + ": " + e);
        });
    },
    cdate(g) {
      return cdate(g);
    },
  },
};
</script>